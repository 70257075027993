import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Philly, BocaRaton, Social } from '../components/address';
import ContactForm from '../components/contact-form';
import { CONSTANTS } from '../utils/constants';

import '../scss/pages/contact-us.scss';

const ContactUs = () => {
	const metaTags = {
		name: 'Contact Us',
		description: 'Deerfield Contact Us',
		keywords: 'Deerfield, Contact Us',
	};

	return (
		<Layout meta={metaTags}>
			<div className='contact-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.WHITE}
					className='contact-header block'
				>
					<Grid>
						<Row>
							<Col xs={1}></Col>
							<Col xs={10} className='careers-content title-block'>
								<p className='header-orange dark'>contact</p>
								<h1 className='dark'>Let's do more &ndash; together</h1>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className=' block'>
					<Grid>
						<Row>
							<Col xs={1} md={1}></Col>
							<Col xs={10} md={4} className='contact-content address-block'>
								<Social className='contact-address' icons={false} />
								<Philly className='contact-address' />
							</Col>

							<Col xs={12} md={6} className='contact-content'>
								<ContactForm />
							</Col>
							<Col xs={1} md={1}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
		</Layout>
	);
};

export default ContactUs;
